import * as styles from './SmallButton.module.scss'

import React from 'react'

import classNames from 'classnames'

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  secondary?: boolean
}

export const SmallButton: React.FC<Props> = (props) => {
  const { secondary = false, ...buttonProps } = props

  return (
    <button
      type="button"
      {...buttonProps}
      className={classNames(styles.button, {
        [styles.primary]: !secondary,
        [styles.secondary]: secondary,
      })}
    />
  )
}

import * as styles from './Indicator.module.scss'

import React from 'react'

interface Props {
  size?: string
}

const Indicator: React.FC<Props> = (props) => {
  const { size } = props

  return (
    <div
      className={styles.indicator}
      style={size ? { width: size, height: size } : {}}
    />
  )
}

export default Indicator

import { compact } from 'lodash'
import { fetchCollection } from './fetchCollection'
import { Deck } from './types'

export async function fetchDeck(id: string): Promise<Deck> {
  const result = await fetch(`https://api.scryfall.com/decks/${id}/export/json`)

  return await result.json()
}

export async function fetchDeckCards(id: string) {
  const deck = await fetchDeck(id)

  return await fetchCollection(
    compact(
      Object.values(deck.entries)
        .flatMap((entries) => entries)
        .map((entry) => {
          return entry.card_digest?.id != null
            ? { id: entry.card_digest.id }
            : null
        }),
    ),
  )
}
